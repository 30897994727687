<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <h3 class="navbar-brand-full">MCST 4560</h3>
        <h3 class="navbar-brand-minimized">M</h3>
        <!-- <img class="navbar-brand-full" src="img/brand/logo.svg" width="89" height="25" alt="CoreUI Logo">
        <img class="navbar-brand-minimized" src="img/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo"> -->
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" :defaultOpen="true" />

      <b-navbar-nav class="ml-auto">
        <b-nav-item class="d-md-down-none">
          <i
            class="icon-user icons"
            v-b-tooltip.hover
            title="Profile"
            @click="$router.push({ name: 'Profile' })"
          ></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i
            class="icon-lock icons"
            v-b-tooltip.hover
            title="Change Password"
            @click="$router.push({ name: 'ChangePassword' })"
          ></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none mr-2">
          <i
            class="icon-power icons"
            v-b-tooltip.hover
            title="Logout"
            @click="handleLogout"
          ></i>
        </b-nav-item>
        <!-- <DefaultHeaderDropdownAccnt/> -->
      </b-navbar-nav>
      <!-- <AsideToggler class="d-none d-lg-block" /> -->
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside />
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="/">MCST 4560</a>
        <span class="ml-1">&copy; 2022 SN Services</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://sea.sg">sea.sg</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb,
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";

export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  /* data() {
    return {
      nav: [],
    };
  }, */
  computed: {
    ...mapGetters("auth", ["user", "token"]),
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label
      );
    },
    nav() {
      const self = this;
      return self.user.role.id == 0 || self.user.role.id == 2
        ? nav.items
        : nav.items.filter((x) => x.name == "Dashboard");
    },
  },
  methods: {
    handleLogout() {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: "auth/logout",
          params: {
            refreshToken: self.token.refreshToken,
          },
        })
        .then((response) => {
          localStorage.removeItem("vuex");

          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.$message.success({
              zIndex: 10000,
              message: response.message,
            });
          }
          window.location.reload();
        });
    },
  },
};
</script>
