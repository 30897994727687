export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "cui-dashboard",
    },
    {
      name: "Management",
      url: "/management",
      icon: "icon-layers",
      children: [
        {
          name: "Building",
          url: "/management/building"
        },
        {
          name: "Unit",
          url: "/management/unit",
        },
        {
          name: "Meter",
          url: "/management/meter",
        },
        {
          name: "Account",
          url: "/management/account",
        },
      ],
    },
    {
      name: "Transaction",
      url: "/transaction",
      icon: "cui-task",
      children: [
        {
          name: "Discount",
          url: "/transaction/discount",
        },
        {
          name: "Adjustments",
          url: "/transaction/adjustments",
        },
        {
          name: "Meter Readings",
          url: "/transaction/meter-readings",
        },
        {
          name: "Invoices",
          url: "/transaction/invoices",
        },
        {
          name: "Payments",
          url: "/transaction/payments",
        },
      ],
    },
    //main menu email
    {
      name: "Email",
      url: "/email",
      icon: "cui-envelope-closed",
      children: [
        {
          name: "Template",
          url: "/email-template",
        },
        {
          name: "Reminder",
          url: "/email-reminder",
        },
        {
          name: "Circular",
          url: "/email-template/circular",
        }
      ]
    },
    {
      name: "Report",
      url: "/report",
      icon: "cui-file",
      children: [
        {
          name: "Debtor Aging",
          url: "/report/debtor-aging",
        },
        {
          name: "Monthly Invoice Details",
          url: "/report/monthly-account-details",
        },
        {
          name: "SOA",
          url: "/report/account-statements",
        }
        /*{
          name: "Non Payment",
          url: "/report/non-payment",
        },
        {
          name: "Monthly Charges",
          url: "/report/monthly-charges",
        },*/
      ],
    },
    {
      name: "Setting",
      url: "/setting",
      icon: "cui-cog",
      children: [
        {
          name: "User Management",
          url: "/setting/user-management",
        },
        {
          name: "Configuration",
          url: "/setting/configuration",
        },
      ],
    },
  ],
};
